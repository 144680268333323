/**
 * BASE
 *
 * Global common functionality.
 */

'use strict';

/*
 * jQuery
 */
var $ = require( 'jquery' );

/*
 * Channel detection
 */
require( './_device' );

/*
 * Base CSS
 */
require( '../sass/base.scss' );

/*
 * Imports
 */
var header         = require( '../../components/header/scripts' ),
    footer         = require( '../../components/footer/scripts' ),
    breadcrumbs    = require( '../../components/breadcrumbs/scripts' ),
    toc            = require( '../../components/gutenberg-table-of-contents/scripts' ),
    LazyLoadImages = require( '../../../node_modules/vanilla-lazyload/dist/lazyload.min' ),
    isMobile       = window.lid && window.lid.channel && window.lid.channel === 'mobile';

/*
 * Vendor JS
 */
require( '../../../node_modules/jquery-validation/dist/jquery.validate' );

/*
 * Global
 */
require( './_forms' );

/*
 * Ready
 */
$(function() {
    /*-- IMAGE LAZY LOAD --*/
    new LazyLoadImages();

    /*-- HEADER --*/
    header();

    /*-- BREADCRUMBS --*/
    breadcrumbs();

    /*-- FOOTER --*/
    footer();

    /*-- ToC --*/
    toc();

    /*-- TABLES --*/
    var $table = $('#main > .wp-block-advgb-table, .lid-table table');

    if ( $table.length ) {
        require( '../../vendor/stacktable/stacktable' );

        $table.each(function() {
            var $this = $(this);

            $this.wrap('<div class="wp-block-advgb-table-wrapper"><div class="wp-block-advgb-table-inner"></div></div>');

            // Responsive card table
            if ( $('thead', $this).length || $('th', $this).length ) {
                $this.cardtable();
            }
            // Solitary one row tables
            else if ( !$('thead', $this).length && $('tr', $this).length === 1 ) {
                $this.addClass('solitary');
            }
            // Default
            else {
                $this.stackcolumns();
            }
        });
    }

    /*-- SWIPER --*/
    if ( $('.swiper-container').length ) {
        var Swiper = require( '../../../node_modules/swiper/dist/js/swiper.js' );

        $('.swiper-placeholder').each(function( i ) {
            var $this      = $(this),
                $parent    = $this.parent(),
                $container = $this.find('.swiper-container'),
                isBrands   = isMobile && $parent.is('.lid-brands'),
                isDesktop  = !isMobile && $parent.is('#lid-reviews'),
                isSolitary = ( ( isMobile || $('#main.main__two-column').length ) && $parent.is('#lid-reviews') );

            $container.addClass('swiper-' + i);
            $this.find('.swiper-button-prev').addClass('swiper-prev-' + i);
            $this.find('.swiper-button-next').addClass('swiper-next-' + i);

            new Swiper('.swiper-' + i, {
                autoplay: { delay: 6000 },
                slidesPerView: ( isSolitary ? 1 : isBrands ? 4 : isDesktop ? 3 : 'auto' ),
                spaceBetween: 16,
                freeMode: true,
                freeModeMomentum: false,
                loop: false,
                navigation: {
                    nextEl: '.swiper-next-' + i,
                    prevEl: '.swiper-prev-' + i
                }
            });
        });
    }

    /*-- ACCORDION --*/
    var $accordion = $('.lid-accordion:not(.lid-accordion--question)');

    if ( $accordion.length ) {
        $accordion.each(function() {
            var $this = $(this),
                $accordionTitle = $this.find('h4');

            $accordionTitle.on('click', function() {
                $(this).parent().toggleClass('active').siblings().removeClass('active');
            });
        });
    }

    /*-- SIDEBAR NAVIGATION --*/
    var $sidebarNav = $('.lid-sidebar-nav');

    if ( $sidebarNav.length ) {
        var $primarySelected = $('#menu-primary .current-page-ancestor a', $sidebarNav);

        $sidebarNav.each(function() {
            var $this        = $(this),
                $menuItem    = $this.find('.menu > li > a'),
                $submenuItem = $this.find('.sub-menu');

            if ( $submenuItem.length ) {
                $menuItem.on('click', function( e ) {
                    $(this).parent().toggleClass('active').children('.sub-menu').slideToggle().siblings().removeClass('active');

                    e.preventDefault();
                });

                // Set Life Insurance as default for Companies pages
                if ( /\/companies\//.test( window.location.href ) || $('body.search-results').length ) {
                    $('.menu > li:eq(0) > a', $this).trigger('click');
                }
            }
        });

        // Set initial expanded section
        if ( $primarySelected ) {
            $primarySelected.trigger('click');
        }

        // Generic sidebar nav, ie. About Us, Contact Us pages
        $('.lid-sidebar-nav--generic .lid-sidebar-nav__heading').on('click', function() {
            $(this).parent().toggleClass('active').children('div').slideToggle();
        });
    }
});