/**
 * FORM VALIDATION: GLOBAL BUTTON SUBMIT BEHAVIOUR
 */

var $submitBtn = $('button.btn-submit');

$submitBtn
	.addClass('submitting')
	.find('strong')
	.text( $submitBtn.data('success-text') )
	.parent()
	.find('.spinner')
	.removeClass('hidden')
	.parent()
	.next('.success-message')
	.removeClass('hidden');