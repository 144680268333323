/**
 * FORM VALIDATION: MYOWN QUOTE FORM
 */

var MyOwnQuoteForm = $('#myown-quote-form'),
	validator = MyOwnQuoteForm.validate({
	rules: {
		'00N90000003hXjn': {
			required: true,
		},
		'00N6F00000EXfZE': {
			required: true,
		},
		'00N6F00000EXfZO': {
			required: true,
		},
		full_name: {
			required: true,
			fullName: true
		},
		mobile: {
			required: true,
			minlength: 10,
			maxlength: 10,
		},
		email: {
			required: true,
			email: true
		}
	},
	messages: {
		'00N90000003hXjn': 'Please enter your state',
		'00N6F00000EXfZE': 'Please enter your health cover',
		'00N6F00000EXfZO': 'Please enter your health cover type',
		full_name: 'Please enter your full name, ie. John Smith',
		mobile: 'Please enter a valid mobile number',
		email: 'Please enter a valid email address'
	},
	submitHandler: function( form ) {
		var userName  = $('input[name="full_name"]', MyOwnQuoteForm).val().split(' '),
			submitBtn = $('button.btn-submit', MyOwnQuoteForm);

		if ( userName.length > 1 && validator.numberOfInvalids() < 1 ) {
			$('input[name="first_name"]', MyOwnQuoteForm).val( userName[0] );
			$('input[name="last_name"]', MyOwnQuoteForm).val( userName[1] );

			// Button submit
			submitBtn
				.addClass('submitting')
				.find('strong')
				.text( submitBtn.data('success-text') )
				.parent()
				.find('.spinner')
				.removeClass('hidden')
				.parent()
				.next('.success-message')
				.removeClass('hidden');

			// Success!
			form.submit();
		}
	}
});

// Custom validations
jQuery.validator.addMethod('fullName', function( value, element ) {
  return this.optional( element ) || /\w\s\w/.test( value );
}, 'Please enter your full name, ie. John Smith');