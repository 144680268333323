/**
 * COMPONENT - BREADCRUMBS
 *
 * Breadcrumbs functonality.
 */

/*
 * jQuery
 */
var $ = require( 'jquery' );

/*
 * Main
 */
function breadcrumbs() {
    var $breadcrumbs = $('#breadcrumbs:not(.breadcrumbs--stepped)'),
        crumbsCount  = $breadcrumbs.find('li').length,
        crumbsWidth  = 0,
        screenWidth  = $(window).width() - 32; // Factor in side gutters

    if ( $breadcrumbs.length ) {
        $breadcrumbs.find('li').each(function() {
            crumbsWidth += $(this).width();
        });

        if ( crumbsWidth > screenWidth ) {
            $('.breadcrumbs__item--home', $breadcrumbs).addClass('breadcrumbs__item--dots');

            for ( var i = crumbsCount - 1; i > 0; i-- ) {
                var $currItem = $('li:eq(' + i + ')', $breadcrumbs),
                    currWidth = $currItem.width();

                $currItem.addClass('breadcrumbs__item--truncate');

                crumbsWidth -= ( currWidth - 60 );

                if ( crumbsWidth <= screenWidth ) { break; }
            }
        }
    }
}

module.exports = breadcrumbs;

