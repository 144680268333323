/**
 * COMPONENT - HEADER
 *
 * Header functionality.
 *
 * @author Martin Ilagan
 * @date   29/10/19
 */

'use strict';

/*
 * jQuery
 */
var $ = require( 'jquery' );

/*
 * Main
 */
function header() {
    var navTimeout, searchTimeout,
        activeClass  = 'menu-active',
        activeSearch = 'search-active',
        $navItem = $('#header .menu > li'),
        $search  = $('#search');

    $navItem.on({
        mouseenter: function() {
            var $this = $(this);

            // Dropdown
            if ( $('.sub-menu', $this).length ) {
                clearTimeout( navTimeout );
                $this.addClass(activeClass).siblings().removeClass(activeClass);
            }

            // Search
            if ( $this.is('.menu-item-search') && $search.length ) {
                clearTimeout( searchTimeout );
                $search.addClass(activeSearch);
            }
        },
        mouseleave: function() {
            var $this = $(this),
                $searchInput = $('.search__input', $search);

            // Dropdown
            if ( $('.sub-menu', $this).length ) {
                navTimeout = setTimeout(function() {
                    $this.removeClass(activeClass);
                }, 1500);
            } else {
                // Search
                if ( !$searchInput.val() ) {
                    searchTimeout = setTimeout(function() {
                        if ( !$searchInput.hasClass('focus') ) {
                            $search.removeClass(activeSearch);
                        }
                    }, 1500);
                }
            }
        },
        click: function( e ) {
            var $this = $(this);

            // Compare Quotes
            if ( $this.is('.menu-item-search') ) {
                e.preventDefault();
            }
        }
    });

    // Search
    $('.search__input', $search).on({
        focus: function() {
           var $this = $(this);
           
           $this.addClass('focus');
           $search.addClass(activeSearch);
        },
        blur: function() {
           var $this = $(this);
           
           $this.removeClass('focus');
           $search.removeClass(activeSearch);
        }
    });

    // Menu dropdown
    var $dropDown = $('<ul id="dropdown" class="hidden" />'),
        $listItem = $('<li />').html( $('#search').clone() ),
        $navMenu  = $('#header .menu > li.menu-item-menu a');

    $listItem.appendTo( $dropDown );

    $navItem.each(function() {
        var $listItem = $('<li />'),
            $anchor   = $(this).children('a'),
            $child    = $(this).children('.sub-menu');

        if ( $child.length ) {
            $anchor.clone().appendTo( $listItem );
            $child.clone().appendTo( $listItem );
            $listItem.appendTo( $dropDown );
        }
    });

    $dropDown.appendTo( $('body') );

    $navMenu.on('click', function( e ) {
        $(this).parent().toggleClass('menu-active');
        $dropDown.toggleClass('hidden');

        e.preventDefault();
    });

    $('#dropdown > li > a').on('click', function( e ) {
        $(this).toggleClass('active').next('.sub-menu').slideToggle();

        e.preventDefault();
    });

    // Deactivate elements if click events are outside
    $(document).on('click touchstart', function( e ) {
        var $container = $('#header .menu, #search, #dropdown'),
            $dropdown  = $('#header .menu > li.menu-item-compare, #header .menu > li.menu-item-guides, #header .menu > li.menu-item-resources');

        if ( ! $container.is(e.target) && $container.has(e.target).length === 0 ) {
            $dropdown.removeClass('menu-active');
            $navMenu.parent().removeClass('menu-active');
            $search.removeClass('search-active');
            $dropDown.addClass('hidden');
        }
    });
}

module.exports = header;