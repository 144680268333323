/**
 * FORM VALIDATION: QUOTE FORM MINI
 */

var qformMini = $('#qform-mini').validate({
	rules: {
		gender: {
			required: true
		},
		age: {
			required: true
		}
	},
	messages: {
		gender: 'Please select your gender',
		age: 'Please select your age',
	},
	submitHandler: function( form ) {
		if ( qformMini.numberOfInvalids() < 1 ) {
			// Button submit
			require( './submit' );

			// Success!
			form.submit();
		}
	}
});

var qformMiniCTA = $('#qform-mini-cta').validate({
	rules: {
		gender: {
			required: true
		},
		age: {
			required: true
		}
	},
	messages: {
		gender: 'Please select your gender',
		age: 'Please select your age',
	},
	submitHandler: function( form ) {
		if ( qformMiniCTA.numberOfInvalids() < 1 ) {
			// Button submit
			require( './submit' );

			// Success!
			form.submit();
		}
	}
});