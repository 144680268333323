/**
 * COMPONENT - FOOTER
 *
 * Footer functionality.
 */

'use strict';

/*
 * jQuery
 */
var $ = require( 'jquery' ),
	isMobile = ( ( window.lid && window.lid.channel && window.lid.channel === 'mobile' ) || ( $('body.mobile').length ) );

/*
 * Main
 */
function footer() {
    $('#footer__links > li > a').on('click', function( e ) {
    	var $parent  = $(this).parent(),
    		$subMenu = $parent.find('.sub-menu');

        $parent.toggleClass('menu-show');

        if ( isMobile ) {
        	if ( $parent.hasClass('menu-show') ) {
        		$subMenu.slideDown();
        	} else {
        		$subMenu.slideUp();
        	}
        }

        e.preventDefault();
    });

    // Trigger first link
    if ( isMobile ) {
	    $('#footer__links > li:eq(0) > a').trigger('click');
	}
}

module.exports = footer;