/**
 * DEVICE
 *
 * Channel detection for pages.
 */

(function() {
    'use strict';

    var channel,
    	ua = window.navigator.userAgent,
    	body = document.querySelector('body'),
        isMobile = /(mobi)/i.test( ua ),
        isTablet = /(tablet)|(iPad)/i.test( ua );

    channel = ( ( ( isMobile && !isTablet ) || ( document.querySelector('body.mobile') ) ) ? 'mobile' : 'desktop' );

    if ( isMobile ) { body.classList.add('mobile'); }
    else if ( isTablet ) { body.classList.add('tablet'); }
    else { body.classList.add('desktop'); }

    window.lid = window.lid || {};
    window.lid.channel = channel;
})();